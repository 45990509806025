import loadable from '../utils/loadable'

const App = loadable(() => import('@/containers/App'));
const Home = loadable(() => import('@/containers/home'));
const HomeClient = loadable(() => import('@/containers/homeClient/index'));
const Login = loadable(() => import('@/containers/login'));
const ClientInfoManage = loadable(() => import('../containers/clientInfoManage/index'));
const BusinessRoleSelect = loadable(() => import('../containers/businessRoleSelect'));
const ClientRelateManage = loadable(() => import('../containers/clientRelateManage/index'));
const UserManage = loadable(() => import('../containers/userManage/index'));
const SystemManage = loadable(() => import('../containers/systemManage/index'));
const RoleManage = loadable(() => import('../containers/roleManage/index'));
const MenuManage = loadable(() => import('../containers/menuManage/index'));
const OrganizeManage = loadable(() => import('../containers/organizeManage/index'));
const OperationManageFm = loadable(() => import('../containers/operationManage/interfaceQueryFm'));
const OperationManageFmJst = loadable(() => import('../containers/operationManage/interfaceQueryFmJst'));
const OperationManageTicket = loadable(() => import('../containers/operationManage/interfaceQueryticketPay'));
const OperationManageTencent = loadable(() => import('../containers/operationManage/interfaceQueryTencent'));
const OperationManageJdh = loadable(() => import('../containers/operationManage/interfaceQuerySupply'));
const OperationManageXsxx = loadable(() => import('../containers/operationManage/interfaceQueryXsxx'));
const OperationManage = loadable(() => import('../containers/operationManage/interfaceQuery'));
const OperationManageDsfTwo = loadable(() => import('../containers/operationManage/interfaceQueryDsfTwo'));
const ProductManage = loadable(() => import('@/containers/productManage'));
const BorrowerCredit = loadable(() => import('@/containers/borrowerCredit'));
const CoreBusinessCredit = loadable(() => import('@/containers/coreBusinessCredit'));
const ContractTemplateManage = loadable(() => import('@/containers/contractTemplateManage'));
const SigningConfiguration = loadable(() => import('@/containers/signingConfiguration'));
const TestifyManage = loadable(() => import('@/containers/testifyManage'));
const CredentialsConfiguration = loadable(() => import('@/containers/credentialsConfiguration'));
const QuotaManage = loadable(() => import('../containers/businessTicketManage'));
const AuditInfo = loadable(() => import('../containers/businessTicketManage/component/auditInfo'));
const AuditInfoCopy = loadable(() => import('../containers/businessTicketManage/component/auditInfoCopy'));
const Publish = loadable(() => import('../containers/businessTicketManage/component/businessPublish'));
const Register = loadable(() => import('../containers/login/register'));
const SetDealPassword = loadable(() => import('../containers/login/setDealPassword'));
const ForgotPassword = loadable(() => import('../containers/login/forgotPassword'));
const SmsCode = loadable(() => import('../containers/login/smsCode'));
const CompanyInfoEntry = loadable(() => import('../containers/companyInfoEntry'));
const IssueManage = loadable(() => import('../containers/businessTicketIssue'));
const IssueManageDetail = loadable(() => import('../containers/businessTicketIssue/component/issueAudit'));
const Evaluation = loadable(() => import('../containers/homeClient/component/evaluationSheet'));
const AddIssuse = loadable(() => import('../containers/businessTicketIssue/component/addIssue'));
const IssueSigin = loadable(() => import('../containers/businessTicketIssue/component/issuseSigin'));
const IssueGetTicket = loadable(() => import('../containers/businessTicketIssue/component/issueGetTicket'));
const PaymoneyAudit = loadable(() => import('../containers/bussinessTicketPayMoney/index'));
const IssueDetail = loadable(() => import('../containers/businessTicketIssue/component/issueDetail'));
const CommercialTicketTrade = loadable(() => import('@/containers/commercialTicketManage/operation/commercialTicketTrade'));
const CtTransforDetail = loadable(() => import('@/containers/commercialTicketManage/operation/component/CtTransforDetail'));
const CtTransforDetailDetail = loadable(() => import('@/containers/commercialTicketManage/operation/component/CtTransforDetailDetail'));
const CtSignDetail = loadable(() => import('@/containers/commercialTicketManage/operation/component/CtSignDetail'));
const CommercialTicketAuthorityManage = loadable(() => import('@/containers/commercialTicketManage/client/commercialTicketAuthorityManage'));
const AddRoleAuthority = loadable(() => import('@/containers/commercialTicketManage/client/component/AddRoleAuthority'));
const ConfirmRoleAuthority = loadable(() => import('@/containers/commercialTicketManage/client/component/ConfirmRoleAuthority'));
const ConfirmRoleAuthorityCopy = loadable(() => import('@/containers/commercialTicketManage/client/component/ConfirmRoleAuthorityCopy'));
const CommercialTicketTradeClient = loadable(() => import('@/containers/commercialTicketManage/client/commercialTicketTradeClient'));
const CtTransforClient = loadable(() => import('@/containers/commercialTicketManage/client/component/CtTransforClient'));
const CtSignClient = loadable(() => import('@/containers/commercialTicketManage/client/component/CtSignClient'));
const AddTransforClient = loadable(() => import('@/containers/commercialTicketManage/client/component/AddTransforClient'));
const CtTransforDetailClient = loadable(() => import('@/containers/commercialTicketManage/client/component/CtTransforDetailClient'));
const InvitateCompanyManage = loadable(() => import('@/containers/companyInformationManage/invitateCompanyManage'));
const StatisticsEcharts = loadable(() => import('@/containers/statisticsEcharts/index'));
const ErrorPage = loadable(() => import('../../src/components/errorPage/index'));
const ChangePassword = loadable(() => import('@/containers/SysManage/changePassword'));
const UserGroup = loadable(() => import('../../src/containers/permissions/personnelManagement/userGroup/index'));
const UserIndex = loadable(() => import('../../src/containers/permissions/index'));
const OpenService = loadable(() => import('../../src/containers/permissions/component/openService'));
const OpenServiceDetail = loadable(() => import('../../src/containers/permissions/component/openServiceDetail'));
const CreatUserGroup = loadable(() => import('../../src/containers/permissions/personnelManagement/userGroup/component/creatUserGroup'));
const AddUserGroup = loadable(() => import('../../src/containers/permissions/personnelManagement/userGroup/component/addUserGroup'));
const CreatuserGroup = loadable(() => import('../../src/containers/permissions/personnelManagement/user/component/creatUser'));
const UserConpont = loadable(() => import('../../src/containers/permissions/personnelManagement/user/index'));
const AddUser = loadable(() => import('../../src/containers/permissions/personnelManagement/user/component/addUser'));
const UserDetail = loadable(() => import('../../src/containers/permissions/personnelManagement/user/component/userDetail'));
const UserGroupDetail = loadable(() => import('../../src/containers/permissions/personnelManagement/userGroup/component/userGroupDetail'));
const RightsManagement = loadable(() => import('../../src/containers/permissions/rightsManagement/index'));
const AddRightManagement = loadable(() => import('../../src/containers/permissions/rightsManagement/component/addRightManagement'));
const TradingInquiry = loadable(() => import('../../src/containers/permissions/tradingInquiry/index'));
const CompanyList = loadable(() => import('../../src/containers/permissions/component/companyList'));
const ModifyOpenService = loadable(() => import('../../src/containers/permissions/component/modifyOpenService'));
const ModifyOpenServiceApi = loadable(() => import('../../src/containers/permissions/component/modifyOpenServiceApi'));
const CompanyDetali = loadable(() => import('../../src/containers/permissions/component/companyDetail'));
const RuleConfiguration = loadable(() => import('@/billingSystem/ruleConfiguration/ruleConfiguration'));
const AddRule = loadable(() => import('@/billingSystem/ruleConfiguration/add'));
const ViewRule = loadable(() => import('@/billingSystem/ruleConfiguration/view'));
const Bill = loadable(() => import('@/billingSystem/payment/bill'));
const BillPay = loadable(() => import('@/billingSystem/payment/billPay'));
const ReCharge = loadable(() => import('@/billingSystem/payment/reCharge'));
const BalancePay = loadable(() => import('@/billingSystem/payment/balancePay'));
const OfflinePay = loadable(() => import('@/billingSystem/payment/offlinePay'));
const NotOutAccount = loadable(() => import('@/billingSystem/outAccount/notOutAccount'));
const OutAccount = loadable(() => import('@/billingSystem/outAccount/outAccount'));
const Detail = loadable(() => import('@/billingSystem/outAccount/detail'));
const ApiFlowQuery = loadable(() => import('@/billingSystem/flowQuery/apiFlowQuery'));
const FlowDetail = loadable(() => import('@/billingSystem/flowQuery/flowDetail'));
const Account = loadable(() => import('@/billingSystem/account/account'));
const AccountDetail = loadable(() => import('@/billingSystem/account/detail'));

const AccountManage = loadable(() => import('../containers/accountManage/index'));
const AccountRecharge = loadable(() => import('../containers/accountManage/component/recharge'));
const TopUp = loadable(() => import('../containers/topUp'));
const Addqichannel = loadable(() => import('../containers/accountManage/component/addqichannel'));
const Moneyaudit = loadable(() => import('../containers/accountManage/component/moneyAudit'));
const TransactionDetails = loadable(() => import('../containers/accountManage/TransactionDetails'));


const FinanceMange = loadable(() => import('../containers/financeManage/index'));
const Financeapply = loadable(() => import('../containers/financeManage/component/financeapply'));
const FinanceBills = loadable(() => import('../containers/financeManage/component/financeBills'));
const CostPayManage = loadable(() => import('../containers/financeManage/component/costPayManage'));
const ServiceChargeDeposit = loadable(() => import('../containers/financeManage/component/serviceChargeDeposit'));
const SetPayPasswordResult = loadable(() => import('../containers/financeManage/component/setPayPasswordResult'));
const FinanceapplyDetail = loadable(() => import('../containers/financeManage/component/financeapplyDetail'));
const FinanceapplyZxDetail = loadable(() => import('../containers/financeManage/component/financeapplyZxDetail'));
const FinanceapplyConfirm = loadable(() => import('../containers/financeManage/component/financeapplyConfirm'));
const FinanceAudit = loadable(() => import('../containers/financeManage/component/financeAudit'));
const FinanceXdAudit = loadable(() => import('../containers/financeManage/component/financeXdAudit'));
const FinanceZxAudit = loadable(() => import('../containers/financeManage/component/financeZxAudit'));
const FinanceOverview = loadable(() => import('../containers/financeManage/component/financeOverview'));
const FinanceapplyModiy = loadable(() => import('../containers/financeManage/component/financeapplyModify'));
const FinanceOfflinePay = loadable(() => import('../containers/financeManage/component/financeOfflinePay'));
const FinanceRefund = loadable(() => import('../containers/financeManage/component/financeRefund'));
const FinancePayDetail = loadable(() => import('../containers/financeManage/component/financePayDetail'));
const FinanceDownLoadContract = loadable(() => import('../containers/financeManage/component/financeDownLoadContract'));
const ContractNoManege = loadable(() => import('../containers/financeManage/component/contractNoManege'));
const PlatDownLoadContract = loadable(() => import('../containers/financeManage/component/platDownLoadContract'));
const FinanceSign = loadable(() => import('../containers/financeManage/component/financesign'));


const Loan = loadable(() => import('../containers/loanManagement/Loan'));
const LoanRegistration = loadable(() => import('../containers/loanManagement/LoanRegistration'));
const LoanRegistrationOrConfirmation = loadable(() => import('../containers/loanManagement/LoanRegistrationOrConfirmation'));
const LoanRegistrationOrConfirmationZx = loadable(() => import('../containers/loanManagement/loanRegistrationOrConfirmationZx'));
const LoanRegistrationXd = loadable(() => import('../containers/loanManagement/LoanRegistrationXd'));
const LoanRecord = loadable(() => import('../containers/loanManagement/LoanRecord'));
const LoanRecordDetail = loadable(() => import('../containers/loanManagement/loanRecordDetail'));
const Repayment = loadable(() => import('../containers/repaymentManagement/Repayment'));
const RepaymentRecord = loadable(() => import('../containers/repaymentManagement/RepaymentRecord'));
const RepaymentRegistration = loadable(() => import('../containers/repaymentManagement/RepaymentRegistration'));
const RepaymentSubmit = loadable(() => import('../containers/repaymentManagement/RepaymentSubmit'));
const Payment = loadable(() => import('../containers/paymentManagement/payment'));
const PaymentRegistration = loadable(() => import('../containers/paymentManagement/paymentRegistration'));
const PaymentSubmit = loadable(() => import('../containers/paymentManagement/paymentSubmit'));
const PaymentRecord = loadable(() => import('../containers/paymentManagement/paymentRecord'));
const payableOrderManage = loadable(() => import('../containers/documentManagement/PayableOrderManage'));
const ReceivableAccountsManage = loadable(() => import('../containers/documentManagement/ReceivableAccountsManage'));
const payableOrderAdd = loadable(() => import('../containers/documentManagement/Add'));
const payableOrderAddRece = loadable(() => import('../containers/documentManagement/AddRece'));
const payableOrderEditor = loadable(() => import('../containers/documentManagement/Editor'));
const payableOrderEditorRece = loadable(() => import('../containers/documentManagement/EditorRece'));
const payableOrderDetail = loadable(() => import('../containers/documentManagement/Detail'));
const payableOrderDetailRece = loadable(() => import('../containers/documentManagement/DetailRece'));
const DealWith = loadable(() => import('../containers/workbench/DealWith'));
const WaitDealt = loadable(() => import('@/containers/workbench/WaitDealt'));

const OpenQft = loadable(() => import('@/containers/activationReminder/component/stepsComp'));


const WorkFlowAdd = loadable(() => import('@/containers/workflow/workflowAdd'));
const WorkFlowView = loadable(() => import('@/containers/workflow/workflowView'));
const WorkFlowEdit = loadable(() => import('@/containers/workflow/workflowEdit'));
const WorkFlowCreate = loadable(() => import('@/containers/workflow/workflowCreate'));
const WorkFlowConfigure = loadable(() => import('@/containers/workflow/workflowConfigure'));
const WorkFlowConfigureAdd = loadable(() => import('@/containers/workflow/configAdd'));
const WorkFlowQuery = loadable(() => import('@/containers/workflow/flowQuery'));

// 微信管理
const WeChatApplictionManage = loadable(() => import('@/containers/weChatManage/weChatApplicationManage'));
const WeChatTagsManage = loadable(() => import('@/containers/weChatManage/weChatTagsManage'));
const WeChatUsersManage = loadable(() => import('@/containers/weChatManage/weChatUsersManage'));

const SupplierInformation = loadable(() => import('@/containers/supplierInformation/index'));

const CreateProject = loadable(() => import('@/containers/createProject'));
const Access = loadable(() => import('@/containers/accessManagement/access'));
const AccessDetail = loadable(() => import('@/containers/accessManagement/accessDetail'));
const AccessAudit = loadable(() => import('@/containers/accessManagement/accessAudit'));

const ReconciliationManagement = loadable(() => import('../containers/reconciliationManagement/index'))
const ReconciliationDetails = loadable(() => import('../containers/reconciliationManagement/component/reconciliationDetails'    ))
const ReconciliationDetailed = loadable(() => import('../containers/reconciliationManagement/component/reconciliationDetailed'))
const ExceptionHanding = loadable(() => import('../containers/reconciliationManagement/component/exceptionHanding'))

const QuotaManagement = loadable(() => import('@/containers/quotaManagement'));

const SupplyChainTicket = loadable(() => import('../containers/supplyChainTicket'));
const OperatorManagement = loadable(() => import('../containers/supplyChainTicket/component/operatorIndex'));
const BusinessRoleSelectJdh = loadable(() => import('../containers/businessRoleSelectJdh'));

// 简单会
const Platforminfo = loadable(() => import('../containers/jdhManagement/jdhOrgInfo'));
const OrgInfo = loadable(() => import('../containers/jdhManagement/jdhOrgInfo/component/orgIndex'));
const DiscountforAudit = loadable(() => import('../containers/jdhManagement/jdhAudit/discountforAudit'));
const GetTicketReplyAudit = loadable(() => import('../containers/jdhManagement/jdhAudit/getTicketReplyAudit'));
const GetTicketPromptAudit = loadable(() => import('../containers/jdhManagement/jdhAudit/getTicketPromptAudit'));
const AcceptanceReplyAudit = loadable(() => import('../containers/jdhManagement/jdhAudit/acceptanceReplyAudit'));
const PromptAcceptanceAudit  = loadable(() => import('../containers/jdhManagement/jdhAudit/promptAcceptanceAudit'));
const TradeBackgroundAudit = loadable(() => import('../containers/jdhManagement/jdhAudit/trardBgAudit'));
const DrawerBill = loadable(() => import('../containers/jdhManagement/jdhAudit/index'));
const OperatorManageModify = loadable(() => import('../containers/jdhManagement/telexAccountManage/components/operatorManageModify'));
const OperatorManageAdd = loadable(() => import('../containers/jdhManagement/telexAccountManage/components/operatorManageAdd'));
const TicketRegistration = loadable(() => import('../containers/jdhManagement/telexAccountManage/components/ticketRegistration'));
const TelexAccountManage = loadable(() => import('../containers/jdhManagement/telexAccountManage'));
const EnterpriseAccountAdd = loadable(() => import('../containers/jdhManagement/jdhRegister/components/enterpriseAccountAdd'));
const EnterpriseRegistration = loadable(() => import('../containers/jdhManagement/jdhRegister/components/enterpriseRegistration'));
const JdhCertification = loadable(() => import('../containers/jdhManagement/jdhRegister/components/enterpriseCertification'));
const JsdDeal = loadable(() => import('../containers/jdhManagement/jdhRegister/components/enterpriseDeal'));
const JsdAudit = loadable(() => import('../containers/jdhManagement/endorseTwo/index'));
const JsdReply = loadable(() => import('../containers/jdhManagement/endorseTwo/component/endorseReply'));
const JsdSearch = loadable(() => import('../containers/jdhManagement/endorseTwo/component/endorseSearch'));
const JsdInfoEdit = loadable(() => import('../containers/jdhManagement/endorseTwo/component/endorseInfoEdit'));
const JsdApplyAudit = loadable(() => import('../containers/jdhManagement/endorseTwo/component/endorseApplyAudit'));
const JsdRevokeaudit = loadable(() => import('../containers/jdhManagement/endorseTwo/component/jsdRevokeaudit'));
const JsdApplyCheck = loadable(() => import('../containers/jdhManagement/endorseTwo/component/endorseApplyCheck'));
const JsdReplyApplyAudit = loadable(() => import('../containers/jdhManagement/endorseTwo/component/endorseReplyApplyAudit'));
const JsdRevoke = loadable(() => import('../containers/jdhManagement/endorseTwo/component/endorseRevoke'));
const JsdReplyApply = loadable(() => import('../containers/jdhManagement/endorseTwo/component/endorseReplyApply'));
const JsdParInfo = loadable(() => import('../containers/jdhManagement/endorseTwo/component/endorseParInfo'));
const EndorseGuaranteeList = loadable(() => import('../containers/jdhManagement/endorseTwo/component/guarantee/endorseGuaranteeList'));
const EndorseGuaranteeApply = loadable(() => import('../containers/jdhManagement/endorseTwo/component/guarantee/endorseGuaranteeApply'));
const EndorseGuaranteeCheck = loadable(() => import('../containers/jdhManagement/endorseTwo/component/guarantee/endorseGuaranteeCheck'));
const EndorseGuaranteeApplyAudit = loadable(() => import('../containers/jdhManagement/endorseTwo/component/guarantee/endorseGuaranteeApplyAudit'));
const EndorseGuaranteeRevocation = loadable(() => import('../containers/jdhManagement/endorseTwo/component/guarantee/endorseGuaranteeRevocation'));
const EndorseGuaranteeRevocationAudit = loadable(() => import('../containers/jdhManagement/endorseTwo/component/guarantee//endorseGuaranteeRevocationAudit'));
const EndorseGuaranteeRespList = loadable(() => import('../containers/jdhManagement/endorseTwo/component/guarantee/endorseGuaranteeRespList'));
const EndorseGuaranteeRespApply = loadable(() => import('../containers/jdhManagement/endorseTwo/component/guarantee/endorseGuaranteeRespApply'));
const EndorseGuaranteeRespApplyAudit = loadable(() => import('../containers/jdhManagement/endorseTwo/component/guarantee/endorseGuaranteeRespApplyAudit'));
const EndorseGuaranteeHistoryList = loadable(() => import('../containers/jdhManagement/endorseTwo/component/guarantee/endorseGuaranteeHistory'));
const EndorseGuaranteeDetail = loadable(() => import('../containers/jdhManagement/endorseTwo/component/guarantee/endorseGuaranteeDetail'));

const SuccessPage = loadable(() => import('../containers/jdhManagement/successPage/index'));
const DrawerIndex = loadable(() => import('../containers/jdhManagement/jdhDrawer/index'));
const DiscountIndex = loadable(() => import('../containers/jdhManagement/jdhDiscount/index'));
const CashIndex = loadable(() => import('../containers/jdhManagement/jdhCash/index'));
const DiscountCost = loadable(() => import('../containers/jdhManagement/discountCost/index'));
const SystemManagement = loadable(() => import('../containers/jdhManagement/system/index'));
const PayCenter = loadable(() => import('../containers/payCenter'));
const PledgeIndex = loadable(() => import('../containers/jdhManagement/jdhPledge/index'))
const PledgeList = loadable(() => import('../containers/jdhManagement/jdhPledge/pledge/index'))
const PledgeInfoEdit = loadable(() => import('../containers/jdhManagement/jdhPledge/pledge/component/pledgeInfoEdit'))
const PledgeApplyCheck = loadable(() => import('../containers/jdhManagement/jdhPledge/pledge/component/pledgeApplyCheck'))
const PledgeApplyAudit = loadable(() => import('../containers/jdhManagement/jdhPledge/pledge/component/pledgeApplyAudit'))
const PledgeAnswer = loadable(() => import('../containers/jdhManagement/jdhPledge/pledge/component/pledgeApplyAnswer'))
const PledgeAnswerApply = loadable(() => import('../containers/jdhManagement/jdhPledge/pledge/component/pledgeAnswerApply'))
const PledgeAnswerApplyAudit = loadable(() => import('../containers/jdhManagement/jdhPledge/pledge/component/pledgeAnswerApplyAudit'))
const PledgeApplyRevoke = loadable(() => import('../containers/jdhManagement/jdhPledge/pledge/component/pledgeApplyRevoke'))
const PledgeApplyRevokeAudit = loadable(() => import('../containers/jdhManagement/jdhPledge/pledge/component/pledgeApplyRevokeAudit'))
const PledgeDetail = loadable(() => import('../containers/jdhManagement/jdhPledge/pledge/component/pledgeDetail'))
const PledgeApplyDetail = loadable(() => import('../containers/jdhManagement/jdhPledge/pledge/component/pledgeApplyDetail'))
const Dictionary = loadable(() => import('../containers/dictionary'))
const SignatureManagement = loadable(() => import('../containers/signatureManagement'))
const AssetPool = loadable(() => import('../containers/assetPool'))
const AssetPackage = loadable(() => import('../containers/assetPackage'))
const AddAssetPackage = loadable(() => import('../containers/assetPackage/component/addAssetPackage'))
const DetailAssetPackage = loadable(() => import('../containers/assetPackage/component/packageDetail'))
const EditAssetPackage = loadable(() => import('../containers/assetPackage/component/editAssetPackage'))
const EditAddAssetPackage = loadable(() => import('../containers/assetPackage/component/editAddAssetPackage'))
const ShortLinkManagement = loadable(() => import('../containers/shortLinkManagement'))
const LetterManagement = loadable(() => import('../containers/letterManagement'))
const OpenSignature = loadable(() => import('../containers/openSignature'))
const Transference = loadable(() => import('../containers/transference'))
const TransferSignature = loadable(() => import('../containers/transferSignature'))
const LetterManagementList = loadable(() => import('../containers/letterPayMoneyManagement/index'))
const LetterManagementAdd = loadable(() => import('../containers/letterPayMoneyManagement/component/letterPayMoney'))
const LetterManagementDetail = loadable(() => import('../containers/letterPayMoneyManagement/component/letterPayMoneyDetail'))
const LetterManagementEd =  loadable(() => import('../containers/letterPayMoneyManagement/component/letterPayMoneyed'))
const FinanceXdAuditDetail = loadable(() => import('../containers/financeManage/component/financeXdAuditDetail'))
const AliBank = loadable(() => import('../containers/dataChart/aliBank'))

export default [
	{
		path: '/admin',
		name: 'App',
		component: App,
		children: [
			{
				path: '/admin/home',
				name: 'home',
				component: Home
			},
			{
				path: '/admin/WaitDealt',
				name: ' 待办',
				component: WaitDealt
			},
			{
				path: '/admin/homeClient',
				name: ' 首页',
				component: HomeClient
			},
			{
				path: '/admin/coreBusinessCredit/:item',
				name: '核心企业授权',
				component: CoreBusinessCredit
			},
			{
				path: '/admin/productManage/:item',
				name: '产品管理',
				component: ProductManage
			},
			{
				path: '/admin/borrowerCredit/:item',
				name: '借款方授信',
				component: BorrowerCredit
			},
			{
				path: '/admin/contractTemplateManage/:item',
				name: '合同模板管理',
				component: ContractTemplateManage
			},

			{
				path: '/admin/clientManage/clientInfo/:item',
				name: '客户信息管理',
				component: ClientInfoManage
			},
			{
				path: '/admin/signingConfiguration/:item',
				name: '产品协议签约配置',
				component: SigningConfiguration
			},
			{
				path: '/admin/testifyManage/:item',
				name: '签约配置',
				component: TestifyManage
			},
			{
				path: '/admin/credentialsConfiguration/:item',
				name: '凭证协议签约配置',
				component: CredentialsConfiguration
			},
			{
				path: '/admin/clientManage/user',
				name: '用户管理',
				component: UserManage
			},
			//平台系统管理
			{
				path: '/admin/clientManage/system',
				name: '平台系统管理',
				component: SystemManage
			},
			//菜单管理
			{
				path: '/admin/clientManage/menu',
				name: '菜单管理',
				component: MenuManage
			},
			//角色管理
			{
				path: '/admin/clientManage/role',
				name: '角色管理',
				component: RoleManage
			},
			//组织架构管理
			{
				path: '/admin/clientManage/organize/:item',
				name: '组织架构管理',
				component: OrganizeManage
			},
			//客户关系管理
			{
				path: '/admin/clientRelateManage/relateMaintain/:item',
				name: '客户关系维护',
				component: ClientRelateManage
			},
			//运营管理
			{
				path: '/admin/operationManage/fmInterfaceQuery',
				name: '接口调用查询（富民）',
				component: OperationManageFm
			},
			{
				path: '/admin/operationManage/ticketInterfaceQuery',
				name: '接口调用查询（票付通）',
				component: OperationManageTicket
			},
			{
				path: '/admin/operationManage/fmJstInterfaceQuery',
				name: '接口调用查询（富民急速贴）',
				component: OperationManageFmJst
			},
			{
				path: '/admin/operationManage/tencentInterfaceQuery',
				name: '接口调用查询（腾讯）',
				component: OperationManageTencent
			},
			{
				path: '/admin/operationManage/supplyChainTicketQuery',
				name: '接口调用查询（简单汇）',
				component: OperationManageJdh
			},
			{
				path: '/admin/operationManage/xsxxInterfaceQuery',
				name: '接口调用查询（线上线下）',
				component: OperationManageXsxx
			},
			{
				path: '/admin/operationManage/interfaceQuery',
				name: '接口调用查询（第三方）',
				component: OperationManage
			},
			{
				path: '/admin/operationManage/interfaceQueryTwo',
				name: '接口调用查询（第三方2）',
				component: OperationManageDsfTwo
			},
			//商票管理
			//运营-商票交易列表
			{
				path: '/admin/commercialTicketManage/CommercialTicketTrade',
				name: '商票交易',
				component: CommercialTicketTrade
			},
			//运营-商票交易列表-转让详情
			{
				path: '/admin/commercialTicketManage/CtTransforDetail/:id/:ticketLedgerId',
				name: '商票转让详情',
				component: CtTransforDetail
			},
			//运营-票据交易列表-签收详情
			{
				path: '/admin/commercialTicketManage/CtSignDetail/:id/:ticketLedgerId',
				name: '商票签收详情',
				component: CtSignDetail
			},
			//运营-票据交易列表-转让详情-转让详情
			{
				path: '/admin/commercialTicketManage/CtTransforDetailDetail/:id/:ticketLedgerId',
				name: '商票转让详情-详情',
				component: CtTransforDetailDetail
			},

			//借款方：商票发行
			{
				path: '/admin/businessTicketIssue/issue',
				name: '商票发行',
				component: IssueManage
			},
			//借款方：商票发行审核页面
			{
				path: '/admin/businessTicketIssue/detail',
				name: '商票发行审核',
				component: IssueManageDetail
			},
			//借款方：商票发行签收页面
			{
				path: '/admin/businessTicketIssue/sigin',
				name: '商票发行签收',
				component: IssueSigin
			},
			//借款方：商票发行收票页面
			{
				path: '/admin/businessTicketIssue/getTicket',
				name: '商票发行收票',
				component: IssueGetTicket
			},
			//借款方：商票付款管理
			{
				path: '/admin/bussinessTicketPayMoney/paymoney',
				name: '商票付款',
				component: IssueManage
			},
			//借款方：商票付款审核页面
			{
				path: '/admin/bussinessTicketPayMoney/paymoneyAudit',
				name: '商票付款',
				component: PaymoneyAudit
			},
			//借款方：商票发行新增
			{
				path: '/admin/businessTicketManage/add',
				name: '商票发行新增',
				component: AddIssuse
			},
			//借款方：商票发行详情页面
			{
				path: '/admin/businessTicketManage/issusedetail',
				name: '商票发行详情',
				component: IssueDetail
			},
			//借款方：企业商票发行申请评估表
			{
				path: '/admin/businessTicketIssue/evaluation',
				name: '企业商票发行申请评估表',
				component: Evaluation
			},

			//借款方-商票交易列表
			{
				path: '/admin/commercialTicketManage/CtTradeClient',
				name: '商票交易',
				component: CommercialTicketTradeClient
			},

			//借款方-票据交易列表-转让详情
			{
				path: '/admin/commercialTicketManage/CtTransfor/:id/:ticketLedgerId',
				name: '商票转让',
				component: CtTransforClient
			},
			//借款方-票据交易列表-签收详情
			{
				path: '/admin/commercialTicketManage/CtSign/:item/:id/:ticketLedgerId/:wkId/:nodeId',
				name: '商票签收',
				component: CtSignClient
			},
			//借款方-票据交易列表-详情
			{
				path: '/admin/commercialTicketManage/CtTransforDetailClient/:item/:id/:ticketLedgerId/:wkId/:nodeId',
				name: '商票转让详情',
				component: CtTransforDetailClient
			},
			// 借款方-票据交易列表-新增转让/重新提交
			{
				path: '/admin/commercialTicketManage/AddTransforClient/:item/:id/:ticketLedgerId',
				name: '新增转让',
				component: AddTransforClient
			},
			// 商票权限管理-借款方
			{
				path: '/admin/commercialTicketManage/CtAuthorityManage',
				name: '商票权限管理',
				component: CommercialTicketAuthorityManage
			},
			{
				path: '/admin/commercialTicketManage/AddRoleAuthority/:item',
				name: '新增他方角色权限',
				component: AddRoleAuthority
			},
			{
				path: '/admin/commercialTicketManage/ConfirmRoleAuthority/:id',
				name: '我方角色权限确认',
				component: ConfirmRoleAuthority
			},
			{
				path: '/admin/commercialTicketManage/ConfirmRoleAuthorityCopy/:id',
				name: '我方角色权限确认',
				component: ConfirmRoleAuthorityCopy
			},
			//企业信息管理
			{
				path: '/admin/companyInformationManage/invitateCompanyManage',
				name: '邀请企业管理',
				component: InvitateCompanyManage
			},

			//业务角色选择
			{
				redirect: '/admin',
				name: 'defaultRoute',
				component: '/admin/home'
			},
			//商票管理:额度管理
			{
				path: '/admin/businessTicketManage/quota',
				name: '额度管理',
				component: QuotaManage
			},
			//商票管理:额度管理详情
			{
				path: '/admin/businessTicketManage/detail',
				name: '额度管理详情',
				component: AuditInfo
			},
			{
				path: '/admin/businessTicketManage/detailCopy',
				name: '额度管理详情',
				component: AuditInfoCopy
			},
			//商票管理:商票发行管理
			{
				path: '/admin/businessTicketManage/publish',
				name: '商票发行管理',
				component: Publish
			},
			//商票管理:商票付款管理
			{
				path: '/admin/businessTicketManage/payMoney',
				name: '商票发行管理',
				component: Publish
			},
			//系统管理
			{
				path: '/admin/SysManage/changePassword',
				name: '修改密码',
				component: ChangePassword
			},
			//计费系统-规则配置
			{
				path: '/admin/billingSystem/ruleConfiguration/list',
				name: '规则配置列表',
				component: RuleConfiguration
			},
			{
				path: '/admin/billingSystem/ruleConfiguration/add',
				name: '添加',
				component: AddRule
			},
			{
				path: '/admin/billingSystem/ruleConfiguration/view',
				name: '详情',
				component: ViewRule
			},
			{
				path: '/admin/billingSystem/payment/billPay',
				name: '账单付款',
				component: BillPay
			},
			{
				path: '/admin/billingSystem/payment/reCharge',
				name: '充值',
				component: ReCharge
			},
			{
				path: '/admin/billingSystem/payment/balancePay',
				name: '余额支付',
				component: BalancePay
			},
			{
				path: '/admin/billingSystem/payment/offlinePay',
				name: '线下支付',
				component: OfflinePay
			},
			{
				path: '/admin/billingSystem/payment/notOutAccount',
				name: '未出账列表',
				component: NotOutAccount
			},
			{
				path: '/admin/billingSystem/payment/outAccount',
				name: '出账列表',
				component: OutAccount
			},
			{
				path: '/admin/billingSystem/outAccount/detail',
				name: '详情',
				component: Detail
			},
			{
				path: '/admin/billingSystem/payment/bill',
				name: '账单列表',
				component: Bill
			},
			{
				path: '/admin/billingSystem/flowQuery/apiFlowQuery',
				name: 'API流水查询',
				component: ApiFlowQuery
			},
			{
				path: '/admin/billingSystem/flowQuery/flowDetail',
				name: '流水详情',
				component: FlowDetail
			},
			{
				path: '/admin/billingSystem/account/account',
				name: '账户列表',
				component: Account
			},
			{
				path: '/admin/billingSystem/account/accountDetail',
				name: '详情',
				component: AccountDetail
			},
			//权限系统
			//权限系统：添加企业
			{
				path: '/admin/permissions/openService',
				name: '用户组',
				component: OpenService
			},
			{
				path: '/admin/permissions/openServiceDetail',
				name: '添加企业详情',
				component: OpenServiceDetail
			},
			//权限系统：用户组
			{
				path: '/admin/permissions/userGroup',
				name: '用户组',
				component: UserGroup
			},
			//权限系统：添加用户组
			{
				path: '/admin/permissions/creatuserGroup',
				name: '添加用户组',
				component: CreatUserGroup
			},
			//权限系统：添加组成员
			{
				path: '/admin/permissions/addUserGroup',
				name: '添加组成员',
				component: AddUserGroup
			},
			//权限系统：添加用户
			{
				path: '/admin/permissions/creatuser',
				name: '添加用户',
				component: CreatuserGroup
			},
			//权限系统：用户列表
			{
				path: '/admin/permissions/user',
				name: '用户列表',
				component: UserConpont
			},
			//权限系统：用户添加用户组
			{
				path: '/admin/permissions/addUser',
				name: '用户添加用户组',
				component: AddUser
			},
			//权限系统：用户详情
			{
				path: '/admin/permissions/userDetail',
				name: '用户详情',
				component: UserDetail
			},
			//权限系统：用户详情
			{
				path: '/admin/permissions/userGroupDetail',
				name: '用户组详情',
				component: UserGroupDetail
			},
			//权限系统：权限管理
			{
				path: '/admin/permissions/rightsManagement',
				name: '权限管理',
				component: RightsManagement
			},
			//权限系统：添加权限
			{
				path: '/admin/permissions/addRightManagement',
				name: '权限管理',
				component: AddRightManagement
			},
			//权限系统：API流水查询
			{
				path: '/admin/permissions/tradingInquiry',
				name: 'API流水查询',
				component: TradingInquiry
			},
			//权限系统：企业列表
			{
				path: '/admin/permissions/companyList',
				name: '企业列表',
				component: CompanyList
			},
			//权限系统：修改企业密钥
			{
				path: '/admin/permissions/modifyOpenService',
				name: '企业列表',
				component: ModifyOpenService
			},
			//权限系统：修改企业Api
			{
				path: '/admin/permissions/modifyOpenServiceApi',
				name: '企业列表',
				component: ModifyOpenServiceApi
			},
			//权限系统：企业详情
			{
				path: '/admin/permissions/companyDetali',
				name: '企业详情',
				component: CompanyDetali
			},
			//账户管理
			{
				path: '/admin/accountManage/accountManage',
				name: '账户管理',
				component: AccountManage
			},
			// //账户管理:充值
			{
				path: '/admin/accountManage/recharge',
				name: '充值',
				component: AccountRecharge
			},
			//账户管理:企富通开通
			// {
			//     path: '/admin/accountManage/openQft',
			//     name: '企富通开通',
			//     component: OpenQft
			// },
			// //账户管理:企富通开通绑卡
			{
				path: '/admin/accountManage/addqichannel',
				name: '企富通开通绑卡',
				component: Addqichannel
			},
			// //账户管理:绑卡/解绑卡小额校验
			{
				path: '/admin/accountManage/moneyaudit',
				name: '绑卡/解绑卡小额校验',
				component: Moneyaudit
			},
			//企富通开通
			{
				path: '/admin/ActivationReminder/:type',
				name: '企富通开通',
				component: OpenQft
			},
			//提现
			{
				path: '/admin/topUp/:type',
				name: '提现',
				component: TopUp
			},
			//账户交易明细
			{
				path: '/admin/accountManage/TransactionDetails',
				name: '账户交易明细',
				component: TransactionDetails
			},

			{
				path: '/admin/accountManage/financeMange',
				name: '融资申请列表',
				component: FinanceMange
			},
			{
				path: '/admin/accountManage/financeapply',
				name: '融资申请提交',
				component: Financeapply
			},
			{
				path: '/admin/accountManage/financeBills',
				name: '融资单查询',
				component: FinanceBills
			},
			{
				path: '/admin/accountManage/financeapplyDetail',
				name: '融资单详情',
				component: FinanceapplyDetail
			},
			{
				path: '/admin/accountManage/financeapplyZxDetail',
				name: '筑信融资单详情',
				component: FinanceapplyZxDetail
			},
			{
				path: '/admin/accountManage/financeapplyConfirm',
				name: '融资确认',
				component: FinanceapplyConfirm
			},
			{
				path: '/admin/accountManage/costPayManage',
				name: '费用支付管理',
				component: CostPayManage
			},
			{
				path: '/admin/accountManage/serviceChargeDeposit',
				name: '服务费保证金',
				component: ServiceChargeDeposit
			},
			{
				path: '/admin/accountManage/setPayPasswordResult',
				name: '结果',
				component: SetPayPasswordResult
			},
			{
				path: '/admin/accountManage/financeAudit',
				name: '审核',
				component: FinanceAudit
			},
			{
				path: '/admin/accountManage/financeXdAudit',
				name: '信单审核',
				component: FinanceXdAudit
			},
			{
				path: '/admin/accountManage/financeZxAudit',
				name: '审核',
				component: FinanceZxAudit
			},
			{
				path: '/admin/accountManage/financeOverview',
				name: '融资总览',
				component: FinanceOverview
			},
			{
				path: '/admin/accountManage/financeapplyModiy',
				name: '融资修改',
				component: FinanceapplyModiy
			},
			{
				path: '/admin/accountManage/financeOfflinePay',
				name: '线下支付登记',
				component: FinanceOfflinePay
			},
			{
				path: '/admin/accountManage/financeRefund',
				name: '退款',
				component: FinanceRefund
			},
			{
				path: '/admin/accountManage/financePayDetail',
				name: '融资单支付详情',
				component: FinancePayDetail
			},
			{
				path: '/admin/accountManage/financeDownLoadContract',
				name: '融资合同下载',
				component: FinanceDownLoadContract
			},
			{
				path: '/admin/accountManage/contractNoManege',
				name: '合同表编管理',
				component: ContractNoManege
			},
			{
				path: '/admin/accountManage/platDownLoadContract',
				name: '平台合同下载',
				component: PlatDownLoadContract
			},
			{
				path: '/admin/accountManage/financeSign',
				name: '签署',
				component: FinanceSign
			},


			//放款管理
			//放款
			{
				path: '/admin/loanManage/loan',
				name: '放款',
				component: Loan
			},
			//放款登记
			{
				path: '/admin/loanManage/loanRegistration',
				name: '放款登记',
				component: LoanRegistration
			},
			//放款登记或确认
			{
				path: '/admin/loanManage/loanRegistrationOrConfirmation',
				name: '放款登记或确认',
				component: LoanRegistrationOrConfirmation
			},
			//放款登记
			{
				path: '/admin/loanManage/loanRegistrationXd',
				name: '放款登记-信单',
				component: LoanRegistrationXd
			},
			//筑信：放款登记或确认
			{
				path: '/admin/loanManage/loanRegistrationOrConfirmationZx',
				name: '筑信放款登记或确认',
				component: LoanRegistrationOrConfirmationZx
			},
			//放款记录
			{
				path: '/admin/loanManage/LoanRecord',
				name: '放款记录',
				component: LoanRecord
			},
			//放款详情
			{
				path: '/admin/loanManage/loanRecordDetail',
				name: '放款详情',
				component: LoanRecordDetail
			},
			//付款
			{
				path: '/admin/payManage/payment',
				name: '付款',
				component: Payment
			},
			//付款登记
			{
				path: '/admin/payManage/paymentRegistration',
				name: '付款登记',
				component: PaymentRegistration
			},
			//付款提交
			{
				path: '/admin/payManage/paymentSubmit',
				name: '付款提交',
				component: PaymentSubmit
			},
			//付款记录
			{
				path: '/admin/payManage/paymentRecord',
				name: '付款记录',
				component: PaymentRecord
			},
			//还款
			{
				path: '/admin/repayManage/Repayment',
				name: '还款',
				component: Repayment
			},
			//还款登记
			{
				path: '/admin/repayManage/RepaymentRegistration',
				name: '还款登记',
				component: RepaymentRegistration
			},
			//还款记录
			{
				path: '/admin/repayManage/RepaymentRecord',
				name: '还款记录',
				component: RepaymentRecord
			},
			//还款提交
			{
				path: '/admin/repayManage/RepaymentSubmit',
				name: '还款提交',
				component: RepaymentSubmit
			},
			//单据管理
			{
				path: '/admin/documentManage/payableOrderManage',
				name: '应付订单管理',
				component: payableOrderManage
			},
			{
				path: '/admin/documentManage/receivableAccountsManage',
				name: '应收账款管理',
				component: ReceivableAccountsManage
			},
			{
				path: '/admin/documentManage/add',
				name: '增加',
				component: payableOrderAdd
			},
			{
				path: '/admin/documentManage/addRece',
				name: '增加',
				component: payableOrderAddRece
			},
			{
				path: '/admin/documentManage/editor',
				name: '编辑',
				component: payableOrderEditor
			},
			{
				path: '/admin/documentManage/editorRece',
				name: '编辑',
				component: payableOrderEditorRece
			},
			{
				path: '/admin/documentManage/detail',
				name: '详情',
				component: payableOrderDetail
			},
			{
				path: '/admin/documentManage/detailRece',
				name: '详情',
				component: payableOrderDetailRece
			},
			// 微信管理
			{
				path: '/admin/WeChatManage/application',
				name: '微信应用管理',
				component: WeChatApplictionManage
			},
			{
				path: '/admin/WeChatManage/tags',
				name: '微信标签管理',
				component: WeChatTagsManage
			},
			{
				path: '/admin/WeChatManage/users',
				name: '微信用户管理',
				component: WeChatUsersManage
			},
			//工作台
			//经办事项
			{
				path: '/admin/dealWith',
				name: '经办事项',
				component: DealWith
			},


			{
				path: '/admin/workflowCreate',
				name: '工作流创建',
				component: WorkFlowCreate
			},
			{
				path: '/admin/workflowConfigure',
				name: '工作流配置',
				component: WorkFlowConfigure
			},
			{
				path: '/admin/workFlowConfigureAdd',
				name: '工作流配置添加',
				component: WorkFlowConfigureAdd
			},
			{
				path: '/admin/workflowAdd',
				name: '工作流创建添加',
				component: WorkFlowAdd
			},
			{
				path: '/admin/workFlowView',
				name: '工作流预览',
				component: WorkFlowView
			},
			{
				path: '/admin/workFlowEdit',
				name: '工作流修改',
				component: WorkFlowEdit
			},
			{
				path: '/admin/workFlow/flowQuery',
				name: '工作流流水查询',
				component: WorkFlowQuery
			},
			{
				path: '/admin/supplierInformation',
				name: '供应商信息管理',
				component: SupplierInformation
			},
			//项目管理
			{
				path: '/admin/createProject/:item',
				name: '创建项目',
				component: CreateProject
			},
			//准入管理
			{
				path: '/admin/accessManage',
				name: '准入管理',
				component: Access
			},
			{
				path: '/admin/accessDetail',
				name: '准入管理详情',
				component: AccessDetail
			},
			{
				path: '/admin/accessAudit',
				name: '准入管理审核',
				component: AccessAudit
			},
			{
				path: '/admin/reconciliation/reconciliationManagement',
				name: '对账管理',
				component: ReconciliationManagement,
			},
			{
				path: '/admin/reconciliation/details',
				name: '对账详情',
				component: ReconciliationDetails,
			},
			{
				path: '/admin/reconciliation/detailed',
				name: '对账明细',
				component: ReconciliationDetailed,
			},
			{
				path: '/admin/reconciliation/error',
				name: '异常处理',
				component: ExceptionHanding,
			},
			{
				path: '/admin/quotaManagement/:item',
				name: '额度管理',
				component: QuotaManagement
			},
			
			{
				path: '/admin/supplyChainTicket/:item',
				name: '供应链票据',
				component: SupplyChainTicket
			},
			{
				path: '/admin/operatorManage/:item',
				name: '操作管理',
				component: OperatorManagement
			},
			//简单会
			{
				path: '/admin/jdhManagement/jsdendorse',
				name: '背书申请列表',
				component: JsdAudit
			},
			{
				path: '/admin/jdhManagement/jsdendorseReply',
				name: '背书应答列表',
				component: JsdReply
			},
			{
				path: '/admin/jdhManagement/jsdendorseSearch',
				name: '背书数据查询',
				component: JsdSearch
			},
			{
				path: '/admin/jdhManagement/jsdendorseInfoEdit',
				name: '背书信息编辑',
				component: JsdInfoEdit
			},
			{
				path: '/admin/jdhManagement/jsdendorseApplyaudit',
				name: '背书申请审核',
				component: JsdApplyAudit
			},
			{
				path: '/admin/jdhManagement/jsdendorseRevokeaudit',
				name: '背书撤销审核',
				component: JsdRevokeaudit
			},
			{
				path: '/admin/jdhManagement/jsdendorseRevokeApplyAudit',
				name: '背书应答审核',
				component: JsdReplyApplyAudit
			},
			{
				path: '/admin/jdhManagement/jsdendorseApplyCheck',
				name: '背书申请核对',
				component: JsdApplyCheck
			},
			{
				path: '/admin/jdhManagement/jsdendorseRevoke',
				name: '背书申请撤销申请',
				component: JsdRevoke
			},
			{
				path: '/admin/jdhManagement/jsdendorseReplyApply',
				name: '背书应答申请',
				component: JsdReplyApply
			},
			{
				path: '/admin/jdhManagement/jsdendorseParInfo',
				name: '票面信息',
				component: JsdParInfo
			},
			{
				path: '/admin/jdhManagement/endorseGuar',
				name: '背书保证申请列表',
				component: EndorseGuaranteeList
			},
			{
				path: '/admin/jdhManagement/endorseGuarApply',
				name: '背书保证申请',
				component: EndorseGuaranteeApply
			},
			{
				path: '/admin/jdhManagement/endorseGuarCheck',
				name: '背书保证申请核对',
				component: EndorseGuaranteeCheck
			},
			{
				path: '/admin/jdhManagement/endorseGuarApplyAudit',
				name: '背书保证申请审核',
				component: EndorseGuaranteeApplyAudit
			},
			{
				path: '/admin/jdhManagement/endorseGuarRevocation',
				name: '背书保证申请撤销',
				component: EndorseGuaranteeRevocation
			},
			{
				path: '/admin/jdhManagement/endorseGuarRevocationAudit',
				name: '背书保证申请撤销审核',
				component: EndorseGuaranteeRevocationAudit
			},
			{
				path: '/admin/jdhManagement/endorseGuarResp',
				name: '背书保证应答列表',
				component: EndorseGuaranteeRespList
			},
			{
				path: '/admin/jdhManagement/endorseGuarRespApply',
				name: '背书保证应答申请',
				component: EndorseGuaranteeRespApply
			},
			{
				path: '/admin/jdhManagement/endorseGuarRespApplyAudit',
				name: '背书保证应答审核',
				component: EndorseGuaranteeRespApplyAudit
			},
			{
				path: '/admin/jdhManagement/endorseGuarHistory',
				name: '背书保证数据查询',
				component: EndorseGuaranteeHistoryList
			},
			{
				path: '/admin/jdhManagement/endorseGuarDetail',
				name: '背书保证票面详情',
				component: EndorseGuaranteeDetail
			},
			{
				path: '/admin/jdhManagement/platformInfo',
				name: '供应链平台信息管理',
				component: Platforminfo
			},
			{
				path: '/admin/jdhManagement/orgInfo',
				name: '供票参与机构信息管理',
				component: OrgInfo
			},
			{
				path: '/admin/jdhManagement/discountforAudit',
				name: '贴现申请审核接口',
				component: DiscountforAudit
			},
			{
				path: '/admin/jdhManagement/getTicketReplyAudit',
				name: '收票应答申请审核',
				component: GetTicketReplyAudit
			},
			{
				path: '/admin/jdhManagement/getTicketPromptAudit',
				name: '提示收票申请审核',
				component: GetTicketPromptAudit
			},
			{
				path: '/admin/jdhManagement/acceptanceReplyAudit',
				name: '承兑应答申请审核',
				component: AcceptanceReplyAudit
			},
			{
				path: '/admin/jdhManagement/promptAcceptanceAudit',
				name: '提示承兑申请审核',
				component: PromptAcceptanceAudit
			},
			{
				path: '/admin/jdhManagement/tradeBackgroundAudit',
				name: '出票贸易背景审核',
				component: TradeBackgroundAudit
			},
			{
				path: '/admin/jdhManagement/drawerBillAudit',
				name: '出票审核',
				component: DrawerBill
			},
			{
				path: '/admin/jdhManagement/operatorManageModify',
				name: '操作员修改',
				component: OperatorManageModify
			},
			{
				path: '/admin/jdhManagement/operatorManageAdd',
				name: '操作员添加',
				component: OperatorManageAdd
			},
			{
				path: '/admin/jdhManagement/ticketRegistration',
				name: '电票登记',
				component: TicketRegistration
			},
			{
				path: '/admin/jdhManagement/telexAccountManage',
				name: '电票账户管理',
				component: TelexAccountManage
			},
			{
				path: '/admin/jdhManagement/jsdAccountAdd',
				name: '企业账户添加',
				component: EnterpriseAccountAdd
			},
			{
				path: '/admin/jdhManagement/jsdRegistration',
				name: '企业账户登记',
				component: EnterpriseRegistration
			},
			{
				path: '/admin/jdhManagement/jsdCertification',
				name: '企业认证',
				component: JdhCertification
			},
			{
				path: '/admin/jdhManagement/jsdDeal',
				name: '企业认证',
				component: JsdDeal
			},
			{
				path: '/admin/pledge/list',
				name: '质押申请',
				component: PledgeList
			},
			{
				path: '/admin/pledge/pledgeInfoEdit',
				name: '质押申请信息编辑',
				component:PledgeInfoEdit
			},
			{
				path: '/admin/pledge/pledgeApplyCheck',
				name: '质押申请信息编辑核对',
				component:PledgeApplyCheck
			},
			{
				path: '/admin/pledge/pledgeApplyAudit',
				name: '质押申请审核',
				component:PledgeApplyAudit
			},
			{
				path: '/admin/pledge/pledgeApplyRevoke',
				name: '质押申请撤销',
				component:PledgeApplyRevoke
			},
			{
				path: '/admin/pledge/pledgeApplyRevokeAudit',
				name: '质押撤销审核',
				component:PledgeApplyRevokeAudit
			},
			{
				path: '/admin/pledge/pledgeDetail',
				name: '质押详情',
				component:PledgeDetail
			},
			{
				path: '/admin/pledge/pledgeApplyDetail',
				name: '质押应答详情',
				component:PledgeApplyDetail
			},
			{
				path: '/admin/pledge/pledgeAnswer',
				name: '质押申请应答',
				component:PledgeAnswer
			},
			{
				path: '/admin/pledge/pledgeAnswerApply',
				name: '质押申请应答申请',
				component:PledgeAnswerApply
			},
			{
				path: '/admin/pledge/pledgeAnswerApplyAudit',
				name: '质押申请应答审核',
				component:PledgeAnswerApplyAudit
			},
			{
				path: '/admin/draw/:item',
				name: '出票',
				component: DrawerIndex
			},
			{
				path: '/admin/discount/:item',
				name: '贴现',
				component: DiscountIndex
			},
			{
				path: '/admin/cash/:item',
				name: '兑付',
				component: CashIndex
			},
			{
				path: '/admin/pledge/:item',
				name: '质押',
				component: PledgeIndex
			},
			{
				path: '/admin/successPage',
				name: '操作成功',
				component: SuccessPage
			},
			{
				path: '/admin/discountCost/:item',
				name: '贴现费用管理',
				component: DiscountCost
			},
			{
				path: '/admin/system/:item',
				name: '供票系统管理-部分页面',
				component: SystemManagement
			},
			{
				path: '/admin/payCenter/:item',
				name: '支付系统-运营',
				component: PayCenter
			},
			{
				path: '/admin/dictionary',
				name: '字典管理-运营',
				component: Dictionary
			},
			{
				path: '/admin/signatureManagement/:item',
				name: '签章管理-运营',
				component: SignatureManagement
			},
			{
				path: '/admin/shortLinkManagement/:item',
				name: '短链接管理-运营',
				component: ShortLinkManagement
			},
			{
				path: '/admin/assetPool/:item',
				name: '资产池管理',
				component: AssetPool
			},
			{
				path: '/admin/assetPackage/list',
				name: '资产包管理',
				component: AssetPackage
			},
			{
				path: '/admin/assetPackage/add',
				name: '资产包管理添加',
				component: AddAssetPackage
			},
			{
				path: '/admin/assetPackage/edit',
				name: '资产包管理详情',
				component: EditAssetPackage
			},
			{
				path: '/admin/assetPackage/editAdd',
				name: '资产包管理详情',
				component: EditAddAssetPackage
			},
			{
				path: '/admin/assetPackage/detail',
				name: '资产包管理详情',
				component: DetailAssetPackage
			},
			{
				path: '/admin/letterManagement/:item',
				name: '信单',
				component: LetterManagement
			},
			{
				path: '/admin/openSignature/:item',
				name: '开立签收',
				component: OpenSignature
			},
			{
				path: '/admin/transference/:item',
				name: '转让',
				component: Transference
			},
			{
				path: '/admin/transferSignature/:item',
				name: '转让签收',
				component: TransferSignature
			},
			{
				path: '/admin/letterPayMoneyManagement/list',
				name: '到期付款',
				component: LetterManagementList
			},
			{
				path: '/admin/letterPayMoneyManagement/add',
				name: '到期付款确认',
				component: LetterManagementAdd
			},
			{
				path: '/admin/letterPayMoneyManagement/detail',
				name: '到期付款详情',
				component: LetterManagementDetail
			},
			{
				path: '/admin/letterPayMoneyManagement/listEd',
				name: '付款数据查询',
				component: LetterManagementEd
			},
			{
				path: '/admin/letterManageAuditment/detail',
				name: '融资单审批',
				component: FinanceXdAuditDetail
			},
			{
				path: '/admin/dataEchart/aliBank',
				name: '阿里云-银行联行号查询',
				component: AliBank
			},
			{
				component: ErrorPage
			}
		]
	},

	//统计页
	{
		path: '/StatisticsEcharts',
		name: '统计页',
		component: StatisticsEcharts
	},
	//登录页
	{
		path: '/login',
		name: 'login',
		component: Login
	},
	{
		path: '/register',
		name: 'register',
		component: Register
	},
	{
		path: '/forgotPassword',
		name: 'forgotPassword',
		component: ForgotPassword
	},
	{
		path: '/smsCode',
		name: 'smsCode',
		component: SmsCode
	},
	{
		path: '/setDealPassword',
		name: 'setDealPassword',
		component: SetDealPassword
	},
	{
		path: '/companyInfoEntry',
		name: 'CompanyInfoEntry',
		component: CompanyInfoEntry
	},
	{
		path: '/businessRole/select',
		name: '业务角色选择',
		component: BusinessRoleSelect
	},
	{
		path: '/businessRoleJdh/select',
		name: '业务角色选择',
		component: BusinessRoleSelectJdh
	},
	{
		component: Login
	},
	{
		redirect: '/',
		name: 'defaultRoute',
		component: '/login'
	},
]